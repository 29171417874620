<template>
  <div class="content-wrapper">
    <div class="row content-header">
      <div class="content-header-left mb-2 col-12">
        <div class="row breadcrumbs-top">
          <div class="col-12 d-flex align-items-center justify-content-between">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Create WP Region
            </h2>
            <router-link
              class="btn btn-primary"
              :to="{ name: 'apps-wp-region-list'}"
            >
              List WP Region
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row match-height">
        <div class="col-12">
          <div
            data-v-5cff0742=""
            class="card"
          ><!----><!---->
            <div
              data-v-5cff0742=""
              class="card-header"
            />
            <div
              data-v-5cff0742=""
              class="card-body"
            ><!----><!---->
              <form
                data-v-5cff0742=""
                class="col-12 row"
              >
                <div
                  data-v-5cff0742=""
                  class="row col-12"
                >
                  <div
                    data-v-5cff0742=""
                    class="col-12"
                  >
                    <div
                      id="__BVID__824"
                      role="group"
                      class="form-row form-group"
                      data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                          id="__BVID__824__BV_label_"
                          for="h-first-name"
                          class="col-md-4 col-form-label"
                        >
                          Name</label>
                        <input
                          id="h-first-name"
                          v-model="name"
                          type="text"
                          placeholder="Name"
                          class="form-control col-11"
                        ><!----><!----><!----></div>
                    </div>
                    <div
                        id="__BVID__824"
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                            for="h-first-name"
                            class="col-md-4 col-form-label"
                        >
                          Sub Region</label>
                        <input
                            v-model="sub_region"
                            type="text"
                            placeholder="sub_region"
                            class="form-control col-11"
                        ><!----><!----><!----></div>
                    </div>
                    <div
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col">
                        <label
                            for="h-first-name"
                            class="col-md-4 col-form-label"
                        >
                          Sub Currency</label>
                        <input
                            v-model="sub_currency"
                            type="text"
                            placeholder="sub_currency"
                            class="form-control col-11"
                        ><!----><!----><!----></div>
                    </div>
                    <div
                        id="__BVID__824"
                        role="group"
                        class="form-row form-group"
                        data-v-5cff0742=""
                    >
                      <div class="col">
                        <button
                            data-v-5cff0742=""
                            type="button"
                            class="btn m-5 btn-primary m-auto"
                            @click.prevent="submitAddRegion()"
                        >
                          Submit
                        </button>
                        <!----><!----><!----></div>
                    </div>
                  </div>
                </div>
              </form>
            </div><!----><!----></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex/dist/vuex.esm.browser'
import { mapGetters } from 'vuex'

export default {

  name: 'WPRegionAdd',
  data() {
    return {
      name: '',
      sub_region: '',
      sub_currency: '',
    }
  },
  created() {
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions(['createWPRegion']),
    submitAddRegion() {
      const data = {
        name: this.name,
        sub_region: this.sub_region,
        sub_currency: this.sub_currency,
      }
      this.createWPRegion(data)
    },
    onFileChange(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg1(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file1 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg2(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file2 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg3(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file3 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    onFileChangeImg4(event) {
      // eslint-disable-next-line prefer-destructuring
      this.file4 = event.target.files[0]
      event.srcElement.parentElement.children[0].textContent = event.target.files[0].name
    },
    loadChildLevel2(event){
      const { options } = event.target
      if (options.selectedIndex > -1) {
        let id = options[options.selectedIndex].getAttribute('id')
        this.listCate2 = this.listCate1.filter(el => {
          // eslint-disable-next-line no-return-assign
          return el.parent_id === id
        })
      }
    },
    loadChildLevel3(event){
      const { options } = event.target
      if (options.selectedIndex > -1) {
        let id = options[options.selectedIndex].getAttribute('id')
        this.listCate3 = this.listCate2.filter(el => {
          // eslint-disable-next-line no-return-assign
          return el.parent_id === id
        })
      }
    },
  },
}
</script>

<style scoped>
#preview {
  /*align-items: center;*/
}

#preview img {
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
}

.input-file {
  border: 1px dashed #ccc;
  display: block;
  width: 100%;
  height: 150px;
}
.input-file-list {
  border: 1px dashed #ccc;
  display: block;
  width: 100%;
  height: 150px;
}

.label-input-file {
  position: relative;
  width: 100%;
}

.label-input-file b {
  display: block;
  width: 100%;
  font-weight: normal;
  text-align: center;
  padding-top: 73px;
  cursor: pointer;
}

.label-input-file input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

</style>
